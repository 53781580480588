export default{
    shtzxyqy: require('@/assets/image/offce/1-20101515130UK.jpg'),
    // agv: [
    //     {
    //         image: require('@/assets/image/offce/agv/1-201015152K4a5.jpg'),
    //     },
    //     {
    //         image: require('@/assets/image/offce/agv/1-201015152P2149.jpg'),
    //     },
    //     {
    //         image: require('@/assets/image/offce/agv/1-201015152QU03.jpg'),
    //     },
    //     {
    //         image: require('@/assets/image/offce/agv/1-201015152RL32.jpg'),
    //     }
    // ],
    patents: [
        {
            image: require('@/assets/image/offce/1-2019106354835.jpg')
        },
        {
            image: require('@/assets/image/offce/1-201921826533X.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2019106354835.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2019218265486.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2019218265630.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2019218269025.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2019218375594.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2019305833744.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2020214398301.jpg')
        },
        {
            image: require('@/assets/image/offce/1-2021227605041.jpg')
        }
    ]
}