<template>
  <div class="Company">

    <PartitionCover cnTitle="公司介绍" enTitle="Company" backgroundImageFileName="company.jpg" />

    <div class="CommonWidth">

      <div class="title">广东世泊智能装备科技有限公司</div>

      <div class="ext">
        广东世泊智能装备科技有限公司是一家专业从事智能立体停车库、智能汽车搬运AGV及其相关设备研发、生产、运维的高科技企业，基于激光导航技术、多电机联合动作控制、虚拟路径规划的智能机器人立体停车整体方案“世泊智停”。世泊智能汇聚了一批经验丰富的高学历和专业技术人才，专注于智能停车系统研发及整体解决方案，先后获得国家专利10余项。
        <br><br>
        “世泊智停”方案提高停车场空间利用率及泊位周转率；“世泊智停”方案施工难度低、建设周期短，助力停车场运营方降低建设成本，更好地实现收益倍增；通过智能调度系统，车主停取车需求与智能机器人之间无缝衔接，可以有效地减少高峰期排队时间，实现真正的零等待停取车，让车主拥有更好的出行体验。
      </div>


      <div class="team">
      <div class="TabTitle">
              <div class="bigtitle">团队实力</div>
              <div class="minentitle">TEAM</div>
      </div>
      <div class="ext">
        中南大学软件工程学院与我们深度合作，并投入大量的人力和物力，智能控制系统是由我们研发完成的。
        <br><br>
        我们的领队梁华庚先生是机械类高级工程师，从事机械研发工作30多年，申报国家专利20多项，
        已取得的各项发明专利、实用新型专利10余项。
        我们的科研人员有博士后、博士、研究生各1人，电器自动化硕士生2人，机械类及软件工程类工
        程师5人以及985、211高等院校高材生多名。
      </div>
      </div>

      <div class="patentShow">

      <div class="TabTitle">
              <div class="bigtitle">专利成果</div>
              <div class="minentitle">PRODUCT DISPLAY</div>
      </div>

      <el-row :gutter="15" class="box">
        <el-col :xs="6" :sm="6" :md="4" :lg="3" v-for="(item, index) in patent.patents" :key="index + 'patents'">
          <div class="item">
            <img :src="item.image" />
          </div>
        </el-col>

        <el-col :xs="6" :sm="6" :md="4" :lg="3" v-for="(item, index) in patent.agv" :key="index + 'agv'">
          <div class="item">
            <img :src="item.image" />
          </div>
        </el-col>

      </el-row>
    </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import PartitionCover from "@/components/PartitionCover.vue";
import patent from '../../data/patent'
export default {
  name: 'Company',
  components: {
    PartitionCover
  },
  data(){
    return{
      patent: {}
    }
  },
  created(){
    this.patent = patent
  }
}
</script>
<style lang="scss" scoped>

  .title{
    margin-top: 150px;
    font-size: 28px;
  }

  .ext{
    margin-top: 30px;
    font-size: 14px;
  }

  .team{
    margin-top: 100px;
  }

  .patentShow{
    margin-top: 150px;
    width: 100%;
    padding-bottom: 100px;
    .box{
      margin-top: 50px;
    }
    .item{
      width: 100%;
      margin-bottom: 15px;
      img{width: 100%;}
    }
  }
</style>