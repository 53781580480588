<template>
  <div
    class="PartitionCover animate__animated animate__fadeInDown"
    :style="{
      backgroundImage:
        'url(' +
        require('@/assets/partition_background/' + backgroundImageFileName) +
        ')',
    }"
  >
  
    <div class="main">
      <div class="CommonWidth">
        <div class="content">
          <div class="cnTitle">
            {{ cnTitle }}
          </div>

          <div class="enTitle">
            {{ enTitle }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PartitionCover",
  props: {
    backgroundImageFileName: {
      type: String,
      default: "02.jpg",
    },
    cnTitle: {
      type: String,
      default: "",
    },
    enTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.PartitionCover {
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-color: #000000;
  background-position: top;background-repeat: no-repeat;
  // margin-bottom: 100px;


  .main {
    background-color: #00131f9f;
    width: 100%;
    padding-bottom: 100%;
  }

  .content {
    // padding-top: 18%;
    padding-top: 150px;
  }

  .cnTitle {
    font-size: 48px;
    color: #ffffff;
    width: 100%;
  }
  .enTitle {
    font-size: 28px;
    color: #a9a9a9;
    font-weight: lighter;
    width: 100%;
    margin-top: 5px;
  }
}
@media screen and (max-width: 850px) {

  .PartitionCover{
    height: 300px !important;
    .content {
      // padding-top: 18%;
      padding-top: 150px;
    }
  }

}
</style>
